<template>
	<page-header-wrapper>
		<a-card :bordered="false">
			<!-- 条件搜索 -->
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="48">
						<a-col :md="6" :sm="24">
							<a-form-item label="客户名称" prop="name">
								<a-input v-model="queryParam.name" placeholder="请输入客户名称" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="手机号" prop="phone">
								<a-input v-model="queryParam.phone" placeholder="请输入手机号" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="工作单位" prop="work">
								<a-input v-model="queryParam.work" placeholder="请输入工作单位" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="省份" prop="province">
								<a-input v-model="queryParam.province" placeholder="请输入省份" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="市区" prop="area">
								<a-input v-model="queryParam.area" placeholder="请输入市区" allow-clear />
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="领取/分配时间" prop="beginTime">
								<a-date-picker @change="getSelectTime($event,'beginTime')" style="width:100%;" placeholder="请选择领取/分配时间"
									v-model="queryParam.beginTime" allow-clear></a-date-picker>
								<!-- <a-input v-model="queryParam.beginTime" placeholder="请输入地区" allow-clear /> -->
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="最后拨打时间" prop="area">
								<a-date-picker @change="getSelectTime($event,'lastCallBeginTime')" style="width:100%;"
									placeholder="请选择最后拨打时间" v-model="queryParam.lastCallBeginTime" allow-clear></a-date-picker>
								<!-- <a-input v-model="queryParam.lastCallBeginTime" placeholder="请输入地区" allow-clear /> -->
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="客户来源" prop="area">
								<a-select placeholder="请选择客户来源" v-model="queryParam.source" allow-clear>
									<a-select-option :value="item.value" v-for="(item,index) in sourceList"
										:key="index">{{item.name}}</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="6" :sm="24">
							<a-form-item label="是否继续跟进" prop="area">
								<a-select placeholder="请选择是否继续跟进" v-model="queryParam.fundingRequirements" allow-clear>
									<a-select-option :value="item.value" v-for="(item,index) in sourceLists"
										:key="index">{{item.name}}</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :md="!advanced && 6 || 24" :sm="24">
							<span class="table-page-search-submitButtons"
								:style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
								<a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
								<a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
								<!-- <a @click="toggleAdvanced" style="margin-left: 8px">
									{{ advanced ? '收起' : '展开' }}
									<a-icon :type="advanced ? 'up' : 'down'" />
								</a> -->
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<!-- 操作 -->
			<div class="table-operations">
				<a-button type="primary" :disabled="multiple" @click="handleToChange(selectedRowKeys)"
					v-hasPermi="['roster:roster:add']">
					批量转入新名单公海
				</a-button>
				<a-input style="width: 200px;" v-model="mobile" placeholder="请输入电话号码" />
				<a-button type="primary" @click="callPhone(mobile)">拨打</a-button>
				<a-button type="primary" @click="getCloudCall(mobile)">云拨打</a-button>
				<!-- <table-setting :style="{float: 'right'}" :table-size.sync="tableSize" v-model="columns"
					:refresh-loading="loading" @refresh="getList" /> -->
			</div>
			<!-- 增加修改 -->
			<create-form ref="createForm" @ok="getList" />
			<!-- 跟进表单 -->
			<follow-up-model ref="FollowUpModel" :list="list" @ok="getList" @next="getNextList"
				@close="maskClose"></follow-up-model>
			<!-- 转入新名单公海 -->
			<a-modal title="转入新名单公海" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
				@cancel="visible=false">
				<a-textarea v-model="reason" placeholder="请输入转入原因" :auto-size="{ minRows: 6, maxRows: 6 }" />
			</a-modal>
			<!-- 数据展示 -->
			<a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
				:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
				:bordered="tableBordered" :scroll="{ x: '100' }">
				<span slot="lastCallTime" slot-scope="text, record">
					{{ parseTime(record.lastCallTime) }}
				</span>
				<span slot="followUpTime" slot-scope="text, record">
					{{ parseTime(record.followUpTime) }}
				</span>
				<span slot="operation" slot-scope="text,record,index">
					<a-divider type="vertical" v-hasPermi="['roster:myroster:look']" />
					<a @click="$refs.createForm.handleLook(record, undefined)" v-hasPermi="['roster:myroster:look']">
						查看
					</a>
					<a-divider type="vertical" v-hasPermi="['roster:myroster:follow']" />
					<a @click="$refs.FollowUpModel.follow(record, index)" v-hasPermi="['roster:myroster:follow']">
						跟进
					</a>
					<a-divider type="vertical" v-hasPermi="['roster:myroster:change']" />
					<a @click="handleToChange(record.id)" v-hasPermi="['roster:myroster:change']">
						转入新名单库公海
					</a>
				</span>
			</a-table>
			<!-- 分页 -->
			<a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
				:total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
				@showSizeChange="onShowSizeChange" @change="changeSize" :pageSizeOptions="pageSizeOptions" />
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		getrosterMy,
		listRoster,
		delRoster,
		receiveRoster,
		batchHighSeas,
		callByPlatform,
		callMobile
	} from '@/api/roster/roster'
	import CreateForm from './modules/CreateForm'
	import FollowUpModel from './modules/FollowUpModel'
	import {
		tableMixin
	} from '@/store/table-mixin'

	export default {
		name: 'Roster',
		components: {
			CreateForm,
			FollowUpModel
		},
		mixins: [tableMixin],
		data() {
			return {
				list: [],
				selectedRowKeys: [],
				selectedRows: [],
				// 高级搜索 展开/关闭
				advanced: false,
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				ids: [],
				loading: false,
				total: 0,
				// 查询参数
				queryParam: {
					fundingRequirements: undefined,
					name: null,
					phone: null,
					work: null,
					area: null,
					province: null,
					beginTime: null,
					endTime: null,
					lastCallBeginTime: null,
					lastCallEndTime: null,
					source: undefined,
					clue: undefined,
					pageNum: 1,
					pageSize: 10,
					type: 1
				},
				sourceList: [{
					name: '新名单库',
					value: 1
				}, {
					name: '线索新名单',
					value: 2
				}],
				sourceLists: [{
					name: '否',
					value: 0
				}, {
					name: '是',
					value: 1
				}],
				columns: [{
						title: '序号',
						ellipsis: true,
						width: 60,
						align: 'center',
						customRender: function(text, record, index) {
							return index + 1
						}
					},
					{
						title: '所属团队',
						dataIndex: 'regionName',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '所属坐席',
						dataIndex: 'userName',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '客户名称',
						width: 200,
						dataIndex: 'name',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '工作单位',
						dataIndex: 'work',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '手机号',
						dataIndex: 'phone',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '上次通话状态',
						width: 200,
						dataIndex: 'lastCallStatus',
						ellipsis: true,
						align: 'center',
						customRender: function(text) {
							if (text == 0) {
								return '未拨打'
							} else if (text == 1) {
								return '未接'
							} else {
								return '已接'
							}
						}
					},
					{
						title: '省份',
						dataIndex: 'province',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '市区',
						dataIndex: 'area',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '客户来源',
						dataIndex: 'source',
						width: 200,
						ellipsis: true,
						align: 'center',
						customRender: function(text) {
							if (text == 1) {
								return '新名单库'
							} else {
								return '线索新名单'
							}
						}
					},
					{
						title: '所属客服',
						dataIndex: 'customerServiceName',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '领取/分配时间',
						dataIndex: 'time',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '通话总次数',
						dataIndex: 'callNum',
						width: 200,
						ellipsis: true,
						align: 'center'
					},
					{
						title: '最后拨打时间',
						width: 200,
						dataIndex: 'lastCallTime',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '最新跟进记录',
						width: 200,
						dataIndex: 'followUpRecords',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '最新一次跟进时间',
						width: 200,
						dataIndex: 'followUpTime',
						ellipsis: true,
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'operation',
						fixed: 'right',
						width: 400,
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				selectList: {}, //选择元素
				confirmLoading: false,
				visible: false,
				reason: '',
				mobile: '', //
				pageSizeOptions: ['10', '50', '80', '100', '200', '400', '600'],
				maskNext: false,
			}
		},
		filters: {},
		created() {
			this.getList()
		},
		activated() {
			this.getList()
		},
		computed: {},
		watch: {},
		methods: {
			// 直接拨打电话
			callPhone(phone) {
				var mobile = '';
				if (phone) {
					mobile = phone;
				} else {
					mobile = this.phone
				}
				callMobile({
					phone: mobile,
					rosterId: ''
				}).then(res => {
					console.log('拨打电话', res);
				})
			},
			// 云拨打
			getCloudCall(phone) {
				let agent = localStorage.getItem('SEATSNUM')
				callByPlatform({
					agent: agent,
					phone: phone,
				}).then(res => {
					if (res.success) {
						console.log('云拨打', res);
					}
				})
			},
			// 选择时间
			getSelectTime(event, type) {
				console.log('时间选择', event, type)
				if (type == 'beginTime') {
					this.queryParam[type] = event.format('YYYY-MM-DD') + ' 00:00:00';
					this.queryParam.endTime = event.format('YYYY-MM-DD') + ' 23:59:59'
				} else {
					this.queryParam[type] = event.format('YYYY-MM-DD') + ' 00:00:00';
					this.queryParam.lastCallEndTime = event.format('YYYY-MM-DD') + ' 23:59:59';
				}

				console.log(this.queryParam[type])
			},
			// 转入公海弹窗
			handleToChange(ids) {
				let idList = [];
				if (typeof ids == 'string') {
					// 字符串
					idList = [{
						id: ids
					}]
				} else if (Array.isArray(ids)) {
					// 数组
					ids.map(item => {
						idList.push({
							id: item
						})
					})
				}
				this.visible = true;
				this.selectList = idList;
			},
			// 确认提交新名单公海
			handleOk() {
				if (!this.reason) {
					this.$message.error('请输入转入原因');
					return false;
				}
				let data = {
					idList: this.selectList,
					reason: this.reason,
					type: 3
				}
				this.confirmLoading = true;
				batchHighSeas(data).then(res => {
					console.log('转入新名单公海', res);
					if (res.success) {
						this.confirmLoading = false;
						this.reason = '';
						this.visible = false;
						this.$message.success(res.message);
						this.getList()
					} else {
						this.$message.error(res.message);
					}
				})
			},
			/** 查询新名单库列表 */
			getList() {
				this.loading = true
				getrosterMy(this.queryParam).then(response => {
					this.list = response.data.records
					this.total = response.data.total
					this.loading = false
					if (this.maskNext) {
						this.$nextTick(() => {
							this.$refs.FollowUpModel.uploadList(this.list)
						})
					}
				})
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParam.pageNum = 1
				this.getList()
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.queryParam = {
					name: null,
					phone: null,
					work: null,
					area: null,
					province: null,
					beginTime: null,
					endTime: null,
					lastCallBeginTime: null,
					lastCallEndTime: null,
					source: undefined,
					clue: undefined,
					pageNum: 1,
					pageSize: 10,
					type: 1,
					fundingRequirements: undefined,
				}
				this.handleQuery()
			},
			onShowSizeChange(current, pageSize) {
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			changeSize(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				this.getList()
			},
			onSelectChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
				this.ids = this.selectedRows.map(item => item.id)
				this.single = selectedRowKeys.length !== 1
				this.multiple = !selectedRowKeys.length
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			},
			/** 删除按钮操作 */
			handleDelete(row) {
				var that = this
				const ids = row.id || this.ids
				if (row.id) {
					this.ids.push(row.id)
				}
				this.$confirm({
					title: '确认删除所选中数据?',
					content: '当前选中编号为' + ids + '的数据',
					onOk() {
						let data = []
						that.ids.map(id => data.push({
							"idList": id
						}))
						return delRoster(data)
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'删除成功',
									3
								)
							})
					},
					onCancel() {
						//取消清空删除ids列表
						that.ids = []
					}
				})
			},
			/** 导出按钮操作 */
			handleExport() {
				var that = this
				this.$confirm({
					title: '是否确认导出?',
					content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						that.download('roster/roster/export', {
							...that.queryParam
						}, `新名单库_${new Date().getTime()}.xlsx`)
					},
					onCancel() {}
				})
			},
			/** 领取按钮操作 */
			handleReceive() {
				var that = this
				this.$confirm({
					title: '是否确认领取?',
					//content: '此操作将导出当前条件下所有数据而非选中数据',
					onOk() {
						return receiveRoster()
							.then(() => {
								that.onSelectChange([], [])
								that.getList()
								that.$message.success(
									'领取成功',
									3
								)
							})
					},
					onCancel() {}
				})
			},
			// 下一页
			getNextList() {
				this.maskNext = true;
				let allPage = Math.ceil(this.total / this.queryParam.pageSize);
				console.log(allPage, this.queryParam.pageNum)
				// if (this.queryParam.pageNum + 1 > allPage) {
				// 	return this.$message.error('已经没有更多数据了...')
				// }
				this.queryParam.pageNum = 1;
				this.getList();
			},
			maskClose() {
				console.log('关闭了')
				this.maskNext = true;
			}
		}
	}
</script>